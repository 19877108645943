import React, { useEffect, useState } from 'react';
import BasicModal from '../components/BasicModal';
import MarketingHead from '../components/marketing-meta';
import MarketingFooter from '../components/MarketingFooter';
import Navbar from '../components/NavBar';

const PricingPage = ()  => {
  const [salesModal, setSalesModal] = useState(false);


  // load the HS script
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/shell.js';
    document.body.appendChild(script);
    script.addEventListener('load', () => {

    })

  }, []);

  // populate a lil form
  useEffect(() => {
    if(window.hbspt && salesModal) {
      window.hbspt.forms.create({
        portalId: '14557468',
        formId: '04f1e797-42b8-4238-90a6-40fd9c2e8496',
        target: '#hubspotForm'
      })
    }
  }, [salesModal])
  
  return (
    <>
    <title>Kodama - Pricing</title>
    <MarketingHead />
    <BasicModal title="Let's get in touch" isOpen={salesModal} close={() => setSalesModal(false)}>
        Interested in funding your project through Kodama's open exchange? Get in touch to list your project on the only open, public carbon offset exchange.
        <div id="hubspotForm"/>
    </BasicModal>
    <div className={'bg-hero-image bg-cover p-1 text-white'}>
        <Navbar />  
        <div className="container xl:px-40 mt-10 mb-8 mx-auto">
          <div className="min-h-96 p-2 grid grid-cols-1 lg:grid-cols-2 gap-2">
            <div className="bg-white rounded-xl p-8 col-span-2 lg:col-span-1 flex flex-col shadow-md">
                <div className="border-b-8 border-green-200 pb-4">
                  <h1 className="text-primary text-xl font-bold mb-1">Our pricing</h1>
                  <p className="text-5xl font-bold text-secondary-dark">0.75% + $0.50</p>
                  <span className="text-primary-dark mt-1 text-sm">per carbon credit purchase</span>
                </div>
                <div className="text-primary-dark mt-4 text-lg flex items-center">
                  <div className="rounded-full p-1 bg-green-100 text-primary w-8 h-8 flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
                        </svg>
                    </div>
                  <div className="ml-2">Getting started is free</div>
                </div>
                <div className="text-primary-dark mt-4 text-lg flex items-center">
                  <div className="rounded-full p-1 bg-green-100 text-primary w-8 h-8 flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z" />
                    </svg>
                    </div>
                  <div className="ml-2">Estimating your footprint is free</div>
                </div>
                <div className="text-primary-dark mt-4 text-lg flex items-center">
                  <div className="rounded-full p-1 bg-green-100 text-primary w-8 h-8 flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                    </svg>
                  </div>
                  <div className="ml-2">Volume pricing availible</div>
                </div> 
                <div className="text-primary-dark mt-4 text-lg flex items-center">
                  <div className="rounded-full p-1 bg-green-100 text-primary w-8 h-8 flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <div className="ml-2">No setup cost or hidden fees</div>
                </div> 
                <div className="flex-grow" />
                <div className="align-self-end mt-8">
                  <button className="rounded-md border text-lg font-bold bg-primary border-primary text-white w-full p-4 text-center">Get started with the API</button>
                </div>
            </div>
            <div className="h-auto min-h-96 p-8 col-span-2 lg:col-span-1 flex items-center">
              <div>
                <p className="text-5xl font-bold">We only charge for what you use</p>
                <div className="px-4 mt-5 mx-2 border-l border-white"> 
                  <p className="mt-2 text-lg">Similar to credit card processors, we charge based upon the transaction. Creating an account and using the API in the test environment is free; get started with your integration without spending a dime.</p>
                  <p className="mt-2 text-lg">Volume pricing for the Kodama API is availible for high volume customers.</p>
                </div>
              </div>
            </div>
          </div>          
        </div>
    </div>
    <div className="container xl:px-40 mt-10 mb-5 mx-auto">
      <div className="bg-white rounded-xl p-8 col-span-2 lg:col-span-1 flex flex-col shadow-md border border-gray-200">
      <h2 className="text-4xl font-bold text-primary-dark text-center">Looking to fund or list your project?</h2>
      <p className="mt-4 lg:px-8 text-center">If you're interested in adding your project or selling your carbon credits on the Kodama marketplace, get in touch to learn more about our process. Currently, our marketplace is only open to projects verified by GoldStandard or the Verra registry.</p>
      <p className="mt-4 lg:px-8 text-center">Fill out the contact form and we'll get in touch about adding your project to the registry.</p>
      <div className="pt-8 w-full flex justify-center">
        <button onClick={() => setSalesModal(true)} className="rounded-md border text-lg font-bold bg-primary border-primary text-white p-4 px-12 text-center">Get in touch</button>
      </div>
      </div>
    </div>
    <MarketingFooter />
    </>
  )
};

export default PricingPage;
